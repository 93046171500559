import { app } from '@/main'

export const CONTEXT_TAGS_BODY = {
  session: [
    {
      text: app.$t('notifications.systemEmails:tag.info.workload'),
      value: 'workload'
    },
    {
      text: app.$t('notifications.systemEmails:tag.info.student'),
      value: 'student'
    }
  ],
  solution: [
    {
      text: app.$t('notifications.systemEmails:tag.info.solution.title'),
      value: 'solutionTitle'
    },
    {
      text: app.$t('notifications.systemEmails:tag.info.session.initial.date'),
      value: 'sessionInitDate'
    },
    {
      text: app.$t('notifications.systemEmails:tag.info.session.initial.hour'),
      value: 'sessionInitHour'
    },
    {
      text: app.$t('notifications.systemEmails:tag.info.session.finish.date'),
      value: 'sessionFinishDate'
    },
    {
      text: app.$t('notifications.systemEmails:tag.info.session.finish.hour'),
      value: 'sessionFinishHour'
    },
    {
      text: app.$t('notifications.systemEmails:tag.info.session.days'),
      value: 'sessionDays'
    }
  ],
  learning_path: [
    {
      text: app.$t('notifications.systemEmails:tag.info.learningPath.title'),
      value: 'learningPathTitle'
    }
  ]
}
