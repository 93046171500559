import { app } from '@/main'

async function addMediation ({ mediation, mediationContainer }) {
  mediation.style.overflow = 'visible'

  const a1 = app.$anime({
    targets: mediationContainer,
    marginBottom: [0, '30px'],
    opacity: [0, 20],
    duration: 150,
    easing: 'linear'
  }).finished

  const a2 = app.$anime({
    targets: [mediation, mediationContainer],
    maxHeight: [0, '3000px'],
    duration: 2500,
    easing: 'linear'
  }).finished

  await Promise.all([a1, a2])
}

async function removeMediation ({ mediation, mediationContainer }) {
  mediationContainer.style.overflow = 'hidden'

  const a1 = app.$anime({
    targets: mediationContainer,
    opacity: -1,
    marginBottom: ['30px', 0],
    maxHeight: [mediationContainer.clientHeight, 0],
    duration: mediation.clientHeight * 0.75,
    easing: 'linear'
  }).finished

  const a2 = app.$anime({
    targets: mediation,
    opacity: -1,
    paddingTop: ['15px', 0],
    paddingBottom: ['15px', 0],
    maxHeight: [mediationContainer.clientHeight, 0],
    duration: mediation.clientHeight * 0.75,
    easing: 'linear'
  })

  await Promise.all([a1, a2])
}

function toggleContainerSizeType (elements, type) {
  const TOGGLE_OPTIONS_TYPE = {
    email,
    none
  }

  TOGGLE_OPTIONS_TYPE[type](elements)

  async function email ({ mediation, mediationContainer }) {
    const animation = app.$anime({
      targets: [mediation, mediationContainer],
      maxHeight: [mediation.clientHeight, '3000px'],
      duration: 1500,
      easing: 'linear',
      begin: () => {
        mediation.style.overflow = 'hidden'
      },
      complete: () => {
        mediation.style.overflow = 'visible'
      }
    }).finished

    await Promise.all([animation])
  }

  async function none ({ mediation, mediationContainer }) {
    const animation = app.$anime({
      targets: [mediation, mediationContainer],
      minHeight: [mediation.clientHeight, 0],
      duration: mediation.clientHeight * 0.5,
      easing: 'linear'
    }).finished

    await Promise.all([animation])
  }
}

async function minimize ({ mediation, mediationContainer }) {
  const animation = app.$anime({
    targets: [mediation, mediationContainer],
    minHeight: [mediation.clientHeight, '70px'],
    duration: 200,
    easing: 'linear'
  }).finished

  await Promise.all([animation])
}

async function maximize ({ mediation, mediationContainer }) {
  mediation.style.minHeight = '0'
  mediation.style.overflow = 'hidden'
  mediationContainer.style.minHeight = '0'
  mediationContainer.style.overflow = 'hidden'

  const animation = app.$anime({
    targets: [mediation, mediationContainer],
    maxHeight: [mediation.clientHeight, '3000px'],
    duration: 1500,
    easing: 'linear'
  }).finished

  app.$anime({
    duration: 350,
    complete: () => {
      mediation.style.overflow = 'visible'
      mediationContainer.style.overflow = 'visible'
    }
  })

  await Promise.all([animation])
}

export const animation = {
  addMediation,
  removeMediation,
  toggleContainerSizeType,
  minimize,
  maximize
}
