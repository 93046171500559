export const RICH_TEXT_EDITOR_OPTIONS = {
  formats: [
    'underline',
    'strike',
    'bold',
    'italic',
    'image',
    'link',
    'align',
    'size'
    // 'video'
    // 'header',
    // 'list',
    // 'blockquote',
    // 'indent'
  ],

  modules: {
    clipboard: {
      matchVisual: false
    }
  }
}
