export function validateTag ({ content, tags }) {
  const checkIsTag = new RegExp('({{.*?}})', 'g')
  const matches = content.match(checkIsTag)
  const allowedTags = tags

  const validatedTags = []

  if (matches) {
    for (const match of matches) {
      const filteredTags = Object.values(allowedTags).filter((tag) => {
        if (typeof match === 'object') {
          return (match[0] === `{{${tag.value}}}`)
        }

        return (match === `{{${tag.value}}}`)
      })

      if (filteredTags.length > 0) {
        validatedTags.push(filteredTags[0])
      }
    }
  }

  const isValid = matches ? validatedTags.length === matches.length : true
  return isValid
}
