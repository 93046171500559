import { app } from '@/main'

export const APPLICABILITY_OPTIONS_FIELD = [
  {
    text: app.$t('notifications.filters.manage:field.option.solution'),
    value: 'solution'
  },
  {
    text: app.$t('notifications.filters.manage:field.option.track'),
    value: 'learning_path'
  },
  {
    text: app.$t('notifications.filters.manage:field.option.program'),
    value: 'program',
    visible: false
  }
]
