export const TYPE_OPTIONS = [
  {
    text: 'Notificações do portal',
    value: 'notification',
    visible: false
  },
  {
    text: 'E-mail',
    value: 'email'
  },
  {
    text: 'SMS',
    value: 'sms',
    visible: false
  },
  {
    text: 'WhatsApp',
    value: 'whatsapp',
    visible: false
  }
]
