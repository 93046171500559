<script>
import { app } from '@/main'
import { mapActions } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'

import { animation } from '../assets/js/ManageTabMediation'
import { validateTag } from '@/support/utils/validateTag'
import { TYPE_OPTIONS, RICH_TEXT_EDITOR_TOOLBAR, RICH_TEXT_EDITOR_OPTIONS, CONTEXT_TAGS_BODY } from '../constants'

const TRANSLATION = {
  formData: {
    filterId: app.$t('notifications.mediation-plan:manage.section.mediations.filter'),
    filterIdUnderDescription: app.$t('notifications.mediation-plan:manage.section.mediations.filter.under-description'),
    type: app.$t('notifications.mediation-plan:manage.section.mediations.type'),
    typeUnderDescription: app.$t('notifications.mediation-plan:manage.section.mediations.type.under-description'),
    messageTitle: app.$t('notifications.mediation-plan:manage.section.mediations.email-title'),
    titleUnderDescription: app.$t('notifications.mediation-plan:manage.section.mediations.email-title.under-description'),
    messageContent: app.$t('notifications.mediation-plan:manage.section.mediations.email-message'),
    messageContentUnderDescription: app.$t('notifications.mediation-plan:manage.section.mediations.email-message-under-description'),
    uploadTypesUnderDescription: app.$t('notifications.mediation-plan:manage.section.mediations.upload-types-under-description'),
    tagsDescription: app.$t('notifications.edit:form.management.tags.info.body'),
    attachment: app.$t('notifications.mediation-plan:manage.section.mediations.attachment'),
    attachmentUnderDescription: app.$t('notifications.mediation-plan:manage.section.mediations.attachment.under-description'),
    attachFile: app.$t('global:form.attach.file')
  },

  feedback: {
    validationError: app.$t('global:feedback.validation.error'),
    hasNotMediation: app.$t('notifications.mediation-plan:manage.section.feedback.has-not-mediation')
  },

  addMediation: app.$t('notifications.mediation-plan:manage.section.mediations.create-plan')
}

export default {
  name: 'ManageTabMediation',

  components: {
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection'),
    RichTextEditor: () => import('@/components/general/RichTextEditor'),
    SelectField: () => import('@/components/general/SelectField'),
    FileField: () => import('@/components/general/FileField')
  },

  props: {
    filterList: {
      type: Array,
      default: () => []
    },

    applicability: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      uniqueKey: 0,
      formData: {
        mediations: []
      }
    }
  },

  validations () {
    return {
      formData: {
        mediations: {
          required,
          $each: {
            filter: {
              id: { required }
            },

            type: { required },

            messageTitle: {
              required: requiredIf(function ({ type }) {
                return type === 'email'
              })
            },

            messageContent: {
              mediationPlanValidateTag: (messageContent) => {
                const isValidTag = validateTag({
                  content: messageContent,
                  tags: this.getTagsBody
                })

                return isValidTag
              },

              required: requiredIf(({ type }) => {
                return type === 'email'
              })
            },

            mediationAsset: {
              maxSize: (file) => {
                if (file && file.length > 0) {
                  if (file[0].size) {
                    return file[0].size < 2000001
                  }
                }

                return true
              }
            }
          }
        }
      }
    }
  },

  computed: {
    getTagsBody () {
      return [
        ...CONTEXT_TAGS_BODY.session,
        ...CONTEXT_TAGS_BODY[this.applicability]
      ]
    }
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        this.$emit('mergeFormDataTab', this.formData)
      }
    },

    '$v.$invalid': {
      deep: true,
      handler () {
        const tab = 'tabMediation'
        const isValid = !this.$v.$invalid

        this.$emit('validationStatus', {
          tab,
          isValid
        })
      }
    }
  },

  created () {
    this.setup()
  },

  methods: {
    ...mapActions([ 'setFeedback' ]),

    setup () {
      this.TRANSLATION = TRANSLATION
      this.TYPE_OPTIONS = TYPE_OPTIONS
      this.RICH_TEXT_EDITOR_TOOLBAR = RICH_TEXT_EDITOR_TOOLBAR
      this.RICH_TEXT_EDITOR_OPTIONS = RICH_TEXT_EDITOR_OPTIONS
    },

    // eslint-disable-next-line vue/no-unused-properties
    checkIsInvalidToParent () {
      this.$v.$touch()

      const isInvalid = this.$v.$invalid

      if (isInvalid) {
        this.setFeedback({ message: TRANSLATION.feedback.validationError })
      }

      return isInvalid
    },

    getIconMinimizedMediation (mediation) {
      return mediation.isMinimized ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
    },

    getHTMLMediationFilterNameByFilterId (filterId) {
      const filter = this.filterList.filter(filter => filter.value === filterId)[0]
      const filterName = filter ? `<span>Filtro: <b>${filter.text}</b></span>` : '<span>Filtro: Carregando...</span>'

      return filterName
    },

    getHTMLMediationTypeNameByType (type) {
      const typeOption = TYPE_OPTIONS.filter(typeOption => typeOption.value === type)[0]
      const typeName = typeOption ? `<span>Tipo de Envio: <b>${typeOption.text}</b></span>` : '<span>Tipo de Envio: Carregando...</span>'

      return typeName
    },

    addTagToBody (tag, index) {
      if (this.formData.mediations[index].messageContent === '') {
        const HTMLTag = `<tr><td style="padding: 0 20px"><p style="font-family: Lato, Arial, sans-serif; margin: 4px 0;line-height: 1">{{${tag}}}</p>`

        this.formData.mediations[index].messageContent = HTMLTag

        return
      }

      const HTMLTag = `{{${tag}}}</p>`
      const messageContent = this.formData.mediations[index].messageContent

      this.formData.mediations[index].messageContent = messageContent.substring(0, messageContent.length - 4) + HTMLTag
    },

    checkIsInvalid () {
      this.$v.$touch()

      const isInvalid = this.$v.$invalid

      return isInvalid
    },

    addMediation () {
      const isValid = this.formData.mediations.length === 0 || !this.checkIsInvalid()

      if (!isValid) {
        this.setFeedback({ message: TRANSLATION.feedback.validationError })

        return
      }

      this.uniqueKey = Math.random()

      const mediation = {
        key: this.uniqueKey,
        isMinimized: false,
        filter: {
          id: null
        },

        type: null,
        messageTitle: null,
        messageContent: '',
        mediationAsset: null
      }

      this.formData.mediations.push(mediation)

      this.$nextTick(() => {
        const elements = {
          mediationContainer: this.$refs[`mediation-container${this.uniqueKey}`][0],
          mediation: this.$refs[`mediation${this.uniqueKey}`][0]
        }

        animation.addMediation(elements)

        this.$v.$reset()
      })
    },

    removeMediation (mediation) {
      const mediationKey = mediation.key

      const elements = {
        mediationContainer: this.$refs[`mediation-container${mediationKey}`][0],
        mediation: this.$refs[`mediation${mediationKey}`][0]
      }

      animation.removeMediation(elements)
        .then(() => {
          this.formData.mediations = this.formData.mediations.filter(mediation => {
            return mediation.key !== mediationKey
          })
        })
    },

    changeType (mediation) {
      const elements = {
        mediationContainer: this.$refs[`mediation-container${mediation.key}`][0],
        mediation: this.$refs[`mediation${mediation.key}`][0]
      }
      const type = mediation.type || 'none'

      animation.toggleContainerSizeType(elements, type)
    },

    toggleMinimizeMediation (mediationIndex, mediationKey) {
      const isValidCurrentMediation = !this.$v.formData.mediations.$each[mediationIndex].$invalid

      if (!isValidCurrentMediation) {
        this.checkIsInvalid()
        this.setFeedback({ message: TRANSLATION.feedback.validationError })

        return
      }

      const elements = {
        mediationContainer: this.$refs[`mediation-container${mediationKey}`][0],
        mediation: this.$refs[`mediation${mediationKey}`][0]
      }

      const isMinimized = this.formData.mediations[mediationIndex].isMinimized

      if (isMinimized) {
        this.formData.mediations[mediationIndex].isMinimized = false
        animation.maximize(elements)

        return
      }

      animation.minimize(elements)
      this.formData.mediations[mediationIndex].isMinimized = true
    }
  }
}
</script>

<template>
  <form
    class="form create-mediation"
    @submit.prevent
  >
    <div
      v-for="(mediation, mediationIndex) in formData.mediations"
      :key="mediation.key"
      :ref="`mediation-container${mediation.key}`"
      class="add-container"
      :set="(validation = $v.formData.mediations.$each[mediationIndex])"
    >
      <Action
        class="btn-remove-mediation"
        type="button"
        icon="delete"
        @click="removeMediation(mediation)"
      />

      <div
        :ref="`mediation${mediation.key}`"
        class="add-mediation"
      >
        <Action
          class="btn-toggle-minimize"
          :icon="getIconMinimizedMediation(mediation)"
          type="button"
          @click="toggleMinimizeMediation(mediationIndex, mediation.key)"
        />
        <input
          :name="`mediations[${mediation.key}][id]`"
          :value="`${mediation.id}`"
          type="hidden"
        >

        <template v-if="mediation.isMinimized">
          <div class="container-minimized">
            <div class="mediation-title">
              {{ mediation.messageTitle }}
            </div>
            <div class="inline">
              <div v-html="getHTMLMediationFilterNameByFilterId(mediation.filter.id)" />
              <div v-html="getHTMLMediationTypeNameByType(mediation.type)" />
            </div>
          </div>
        </template>

        <template v-else>
          <div class="inline">
            <SelectField
              v-model="mediation.filter.id"
              class="add-mediation--filter"
              :label="TRANSLATION.formData.filterId"
              :under-description="TRANSLATION.formData.filterIdUnderDescription"
              :items="filterList"
              :validation="validation.filter.id"
            />

            <SelectField
              v-model="mediation.type"
              class="add-mediation--type"
              :label="TRANSLATION.formData.type"
              :under-description="TRANSLATION.formData.typeUnderDescription"
              :items="TYPE_OPTIONS"
              :validation="validation.type"
              @change="changeType(mediation)"
              @clear="changeType(mediation)"
            />
          </div>

          <div
            v-if="mediation.type === 'email'"
            class="email-area"
          >
            <InputField
              v-model="mediation.messageTitle"
              :label="TRANSLATION.formData.messageTitle"
              :under-description="TRANSLATION.formData.titleUnderDescription"
              :counter="100"
              :validation="validation.messageTitle"
            />

            <RichTextEditor
              v-model="mediation.messageContent"
              class="rich-text"
              :description="TRANSLATION.formData.messageContent"
              :under-description="[TRANSLATION.formData.messageContentUnderDescription, TRANSLATION.formData.uploadTypesUnderDescription]"
              :toolbar="RICH_TEXT_EDITOR_TOOLBAR"
              :options="RICH_TEXT_EDITOR_OPTIONS"
              :validation="validation.messageContent"
            />
            <p class="tags-description">
              {{ TRANSLATION.formData.tagsDescription }}
            </p>
            <ul class="tags-list">
              <li
                v-for="(item, indexTag) in getTagsBody"
                :key="indexTag"
                class="tag-item"
                @click="addTagToBody(item.value, mediationIndex)"
              >
                <span class="tag-item-value">{{ item.text }}</span>
              </li>
            </ul>
            <FormSection :title="TRANSLATION.formData.attachment">
              <FileField
                v-model="mediation.mediationAsset"
                :under-description="TRANSLATION.formData.attachmentUnderDescription"
                :label="TRANSLATION.formData.attachFile"
                accept="*"
                :multiple="false"
                :validation="$v.formData.mediations.$each[mediationIndex].mediationAsset"
                is-enabled-delete-button
                @clear="mediation.mediationAsset = null"
              />
            </FormSection>
          </div>
        </template>
      </div>
    </div>

    <Action
      slot="button"
      class="btn-create-mediation"
      :text="TRANSLATION.addMediation"
      type="button"
      primary
      fixed-width
      @click="addMediation()"
    />
  </form>
</template>

<style lang="scss" src="../assets/scss/ManageTabMediation.scss"></style>
