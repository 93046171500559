export const RICH_TEXT_EDITOR_TOOLBAR = [
  [
    {
      size: [
        'small',
        false,
        'large'
      ]
    }
  ],
  [
    'bold',
    'italic',
    'underline',
    'strike'
  ],
  [
    { align: '' },
    { align: 'center' },
    { align: 'right' },
    { align: 'justify' }
  ],
  [ 'clean' ],
  [ 'link' ],
  [ 'image' ]
]
